import { Loader, notificationConnect, NotificationProvider, ToastConsumer } from '@livechat/design-system';
import '@livechat/design-system/dist/design-system.css';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { ErrorCard } from '../../common/components/livechat/ErrorCard';
import { LoaderContainer } from '../../common/components/livechat/Loader';
import { SupportCard } from '../../common/components/livechat/SupportCard';
import { useLivechat } from '../../common/hooks/useLivechat';
import { ConnectTwitterCard, ConnectTwitterCardProps } from '../components/ConnectTwitterCard';
import { GroupSelectionCard, GroupSelectionCardProps } from '../components/GroupSelectionCard';
import { Queries } from '../constants/queries';
import { useApiClient } from '../hooks/useApiClient';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Settings = () => {
  const {
    error: loginError,
    loading: loginLoading,
    loggedIn,
  } = useLivechat({
    loginUrl: '/api/livechat-twitter-comments/connect/livechat',
  });

  const client = useApiClient();
  const {
    data: organization,
    isLoading: organizationLoading,
    error: organizationError,
  } = useQuery(Queries.getOrganization, client.getOrganization, { enabled: loggedIn });

  const ToastedConnectTwitterCard: React.ComponentType<Omit<ConnectTwitterCardProps, 'notificationSystem'>> = useMemo(
    () => notificationConnect(ConnectTwitterCard as never) as any,
    []
  );

  const ToastedGroupSelectionCard: React.ComponentType<Omit<GroupSelectionCardProps, 'notificationSystem'>> = useMemo(
    () => notificationConnect(GroupSelectionCard as never) as any,
    []
  );

  if (loginError) {
    return <ErrorCard error="Login failed" />;
  }

  if (loginLoading || organizationLoading) {
    return (
      <LoaderContainer>
        <Loader size="large" />
      </LoaderContainer>
    );
  }

  if (organizationError || !organization) {
    return <ErrorCard error="Organization error" />;
  }

  return (
    <NotificationProvider itemsLimit={2} queueLimit={1}>
      <ToastConsumer horizontalPosition="center" fixed verticalPosition="top" />
      <Container>
        <Row>
          <ToastedConnectTwitterCard organization={organization} />
          <ToastedGroupSelectionCard />
          <SupportCard />
        </Row>
      </Container>
    </NotificationProvider>
  );
};
